import React from 'react'
import { Helmet } from 'react-helmet'

const SchemaEvent = ({
  url, title, description, startDateIso, endDateIso, location, opengraphImage = {},
}) => {
  const {
    title: locationTitle = '', address = '', town = '', region = '', country = '',
  } = location || {}

  const structuredDataEvent = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: title,
    description,
    image: opengraphImage ? opengraphImage.src : '',
    url: `${process.env.SITE_BASE_URL}${url}`,
    startDate: startDateIso,
    endDate: endDateIso,
    location: {
      '@type': 'Place',
      name: locationTitle,
      address: {
        '@type': 'Text',
        streetAddress: address,
        addressLocality: town,
        addressRegion: region,
        addressCountry: country,
      },
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredDataEvent, null, 2)}
      </script>
    </Helmet>
  )
}

export default SchemaEvent
