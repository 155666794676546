import React from 'react'
import { Helmet } from 'react-helmet'

const MetaTagsEvent = ({ date, author }) => (
  <Helmet>
    <meta
      property="og:type"
      content="article"
    />
    <meta
      property="og:published_time"
      content={date}
    />
    <meta
      property="og:author"
      content={author}
    />
  </Helmet>
)

export default MetaTagsEvent
